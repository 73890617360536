import { SynopticLogoIcon } from '@synoptic/ui-kit/icons/react/synoptic-logo.js';
import { splashScreen, splashScreenLogo } from './splash-screen.css';
import { useEffect } from 'react';
import { setMeasurement } from '@sentry/react';

export const SplashScreen = () => {
  useEffect(() => {
    return () => {
      setMeasurement('ui.splash_screen.hide', performance.now(), 'millisecond');
    };
  });

  return (
    <div id="splash-screen" className={splashScreen}>
      <SynopticLogoIcon className={splashScreenLogo} />
    </div>
  );
};
